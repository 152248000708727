.article-items {
	margin: 0 auto;
	padding: 50px 0;

	.entry-meta {
		font-weight: bold;
	}
}

.single {
	.entry-meta {
		text-align: center;
	}
	.meta-cat {
		margin-bottom: 40px;
	}
}