.scroll-top {
	width: 40px;
    margin: 0 auto;
    padding: 30px 0;
}

.cd-top.cd-is-visible {
  /* the button becomes visible */
  visibility: visible;
  opacity: 1;
  color: #ff8400;
}
.cd-top.cd-fade-out {
  /* if the user keeps scrolling down, the button is out of focus and becomes less visible */
  //opacity: .5;
}