button#menu-toggle {
	background: none;
	float: right;
}

ul#menu-header-menu {
    float: right;
    position: relative;
    top: 20px;
}

.nav {
	float: right;
}

nav.navbar.navbar-default.navbar-fixed.navbar-transparent {
	border-bottom: 1px solid #f2f2f2;
}

nav li.menu-item {
	float: left;
}

@media (max-width: 1024px) {
	ul#menu-header-menu {
		display: none;
	}
}

@media (max-width: 1025px) {
	.navbar .navbar-nav.navbar-uppercase>li>a {
		font-size: 11px;
	}
}