.author_bio_section{
	background: none repeat scroll 0 0 #F5F5F5;
	padding: 15px;
	margin: 50px 0;
}

.author_name{
	font-size:16px;
	font-weight: bold;
}

.author_details img {
	border: 1px solid #D8D8D8;
	border-radius: 50%;
	float: left;
	margin: 0 10px 10px 0;
}