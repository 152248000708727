body {
	    font-family: "brandon-grotesque",sans-serif;
}

a, p, .entry-title, .comments {
	-ms-word-break: break-all;
     word-break: break-all;

     // Non standard for webkit
     word-break: break-word;

-webkit-hyphens: auto;
   -moz-hyphens: auto;
        hyphens: auto;
}

blockquote {
	background: #FF8400;
	color: white;
	padding: 30px;
	border-left: 13px solid #000;
}