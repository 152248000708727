#comments {
	text-align: initial;
}

.comment-list {
  @extend .list-unstyled;

  time {
  	font-size: 80%;
  }
  .even {
  	background: #f9f9f9;
    padding: 20px;
  }
  .odd {
  	background: #ECF0F1;
  	padding: 30px;
  }
}
.comment-list ol {
  list-style: none;
}
.comment-form p {
  @extend .form-group;
}
.comment-form input[type="text"],
.comment-form input[type="email"],
.comment-form input[type="url"],
.comment-form textarea {
  @extend .form-control;
}
.comment-form input[type="submit"] {
  @extend .btn;
  @extend .btn-secondary;
}


