/* Where we worked page */

.page-header{
	margin-bottom: 50px;
	text-align: center;
}

.main.main-worked-page {
	margin-bottom: 100px;
}

.main {
	nav {
		margin: 50px 0;
	}
	.page-bar {
		text-align: center;
		margin: 50px 0;
	}
}

a.page-numbers {
	border: 1px solid #ddd;
    background: #FF8400;
    padding: 5px;
    color: white;
}